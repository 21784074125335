import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel,
  Table,
} from "react-bootstrap";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import InputMask from "react-input-mask";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { isMobile } from "react-device-detect";

import FooterZ from "../../components/Footer/FooterZ.jsx";
import HeaderM from "../../components/Header/HeaderM.jsx";

import box_banner from "../../assets/img/zenite/novo/banner-box.png";

const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: "include",
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const GET_LAWSUIT = gql`
  query lawsuits_collections {
    lawsuits_collections(next: 1) {
      lawsuits {
        id
        title
        subtitle
        description
        presenter
        thumbnail
        accreditation_start_date_title
        accreditation_start_hours_title
        text_date
        knowledgeTrail {
          id
          title
          description
          link
          thumbnail
        }
      }
    }
  }
`;

const GET_USER_HAS_LAWSUIT = gql`
  query user_has_lawsuit($lawsuit_id: Int!) {
    user_has_lawsuit(lawsuit_id: $lawsuit_id) {
      id
      isDisable
    }
  }
`;

const REGISTER_UPDATE = gql`
  mutation updateByUser(
    $name: String!
    $nickname: String!
    $mail: String!
    $phone: String!
    $pass: String!
    $repass: String!
    $accepted_news: Int!
  ) {
    updateByUser(
      name: $name
      nickname: $nickname
      mail: $mail
      phone: $phone
      pass: $pass
      repass: $repass
      accepted_news: $accepted_news
    ) {
      id
      token
      msg
      msg_error
    }
  }
`;

class LawsuitList extends Component {


  constructor() {
    super();
    this.state = {
      start_state: true,
      accepted_news: false,
      accepted_terms: false,
      name: "",
      nickname: "",
      mail: "",
      phone: "",
      pass: "",
      repass: "",
      msg: "",
      msg_type: "info",
    };
  }

  updateByUser = async () => {


    if (!this.state.accepted_terms) {
      this.setState({
        msg_type: "warning",
        msg: "Você precisa concordar com os termos",
      });
    } else {
      const mutationReturn = (
        await account_client.mutate({
          variables: {
            name: this.state.name,
            nickname: this.state.nickname,
            mail: this.state.mail,
            phone: this.state.phone,
            pass: this.state.pass,
            repass: this.state.repass,
            accepted_news: this.state.accepted_news,
          },
          mutation: REGISTER_UPDATE,
          fetchPolicy: "no-cache",
        })
      ).data.updateByUser;

      if (
        mutationReturn &&
        typeof mutationReturn.token != "undefined" &&
        mutationReturn.token !== null &&
        mutationReturn.token !== ""
      ) {
        document.cookie = "token_user=" + mutationReturn.token; // Grava o cookie
        this.setState({
          msg_type: "info",
          msg: "Dados atualizados com sucesso.",
        });
        window.location.replace("/");
      } else if (
        mutationReturn &&
        typeof mutationReturn.msg_error != "undefined"
      ) {
        this.setState({
          msg_type: "warning",
          msg: mutationReturn.msg_error,
        });
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.updateByUser();
  };

  onChange_name = (event) => {
    this.setState({ name: event.target.value });
  };
  onChange_nickname = (event) => {
    this.setState({ nickname: event.target.value });
  };
  onChange_mail = (event) => {
    this.setState({ mail: event.target.value });
  };
  onChange_phone = (event) => {
    this.setState({ phone: event.target.value });
  };
  onChange_pass = (event) => {
    this.setState({ pass: event.target.value });
  };
  onChange_repass = (event) => {
    this.setState({ repass: event.target.value });
  };

  handleCheckboxChange = (e) => {
    this.setState((state) => ({
      ...state,
      accepted_terms: !this.state.accepted_terms,
    }));
  };

  handleCheckboxChange_news = (e) => {
    this.setState((state) => ({
      ...state,
      accepted_news: !this.state.accepted_news,
    }));
  };

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  minutes_with_leading_zeros(dt) {
    return (dt.getMinutes() < 10 ? "0" : "") + dt.getMinutes();
  }

  render() {
    return (
      <div>
        <div className="content">
          {isMobile && <HeaderM />}

          <div className="container">
            <Grid fluid>
              <Query
                client={account_client}
                fetchPolicy="no-cache"
                query={gql`
                  {
                    get_authenticated_user {
                      id
                      name
                      nickname
                      mail
                      uid
                      phone
                      is_confirm
                    }
                  }
                `}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Olá</div>;
                  if (error) return <div>Erro :(</div>;

                  var authenticated_user = data.get_authenticated_user;

                  if (this.state.start_state) {
                    this.setState({
                      start_state: false,
                      name: authenticated_user.name,
                      nickname: authenticated_user.nickname,
                      mail: authenticated_user.mail,
                      phone: authenticated_user.phone,
                    });
                  }

                  if (authenticated_user.is_confirm !== 1) {
                    return (
                      <div>
                        <form
                          onSubmit={(e) => {
                            this.handleSubmit(e);
                            e.preventDefault();
                          }}
                        >
                          <h1 className="titleTop">Confirme Seus Dados</h1>

                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                onChange: this.onChange_name,
                                name: "name",
                                label: "Nome Completo",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Nome Completo",
                                defaultValue: this.state.name,
                              },
                            ]}
                          />

                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                onChange: this.onChange_nickname,
                                name: "nickname",
                                label: "Nome para o Chat",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Nome para o Chat",
                                defaultValue: this.state.nickname,
                              },
                            ]}
                          />

                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                onChange: this.onChange_mail,
                                name: "mail",
                                label: "E-mail",
                                type: "mail",
                                bsClass: "form-control",
                                placeholder: "E-mail",
                                defaultValue: this.state.mail,
                              },
                            ]}
                          />

                          <FormGroup>
                            <ControlLabel>Celular</ControlLabel>
                            <div>
                              <InputMask
                                className="form-control"
                                mask="(99) 9 9999-9999"
                                value={this.state.phone}
                                onChange={this.onChange_phone}
                              />
                            </div>
                          </FormGroup>

                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                onChange: this.onChange_pass,
                                name: "pass",
                                label: "Senha",
                                type: "password",
                                bsClass: "form-control",
                                placeholder: "Senha",
                                defaultValue: "",
                              },
                            ]}
                          />

                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                onChange: this.onChange_repass,
                                name: "repass",
                                label: "Repetir Senha",
                                type: "password",
                                bsClass: "form-control",
                                placeholder: "Repetir Senha",
                                defaultValue: "",
                              },
                            ]}
                          />

                          <FormGroup>
                            <ControlLabel>
                              <input
                                value="1"
                                name="accepted_terms"
                                type="checkbox"
                                checked={this.state.accepted_terms}
                                onChange={this.handleCheckboxChange}
                              />{" "}
                              Concordo com os{" "}
                              <a
                                target="_blank"
                                href="/account/assets/terms.pdf"
                              >
                                termos e condições
                              </a>
                            </ControlLabel>
                          </FormGroup>

                          <FormGroup>
                            <ControlLabel>
                              <input
                                value="1"
                                name="accepted_news"
                                type="checkbox"
                                checked={this.state.accepted_news}
                                onChange={this.handleCheckboxChange_news}
                              />{" "}
                              Aceito receber novidades
                            </ControlLabel>
                          </FormGroup>

                          <Row>
                            <Col md={12}>
                              <Button bsStyle="info" fill type="submit">
                                ATUALIZAR DADOS
                              </Button>
                            </Col>
                            {this.state.msg && (
                              <Col md={10}>
                                <Alert bsStyle={this.state.msg_type}>
                                  {this.state.msg}
                                </Alert>
                              </Col>
                            )}
                          </Row>
                        </form>
                      </div>
                    );
                  }

                  return (
                    <div>
                      {/* <h1 className="titleTop">Meus Eventos</h1> */}
                      <h2 className="h2-zenite">Meus Cursos</h2>

                      <Row>
                        <Query client={account_client} query={GET_LAWSUIT}>
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;

                            if (
                              !data.lawsuits_collections.lawsuits ||
                              !data.lawsuits_collections.lawsuits.length
                            ) {
                              return (
                                <Col lg={12}>
                                  <h1>Nenhum evento ativo.</h1>
                                </Col>
                              );
                            }

                            return data.lawsuits_collections.lawsuits.map(
                              (item, k) => (
                                <Query
                                  client={account_client}
                                  query={GET_USER_HAS_LAWSUIT}
                                  variables={{
                                    lawsuit_id: parseInt(item.id),
                                  }}
                                >
                                  {({ loading, error, data }) => {
                                    if (loading){
                                      return <React.Fragment>Carregando...</React.Fragment>;
                                    }

                                    var user_has_trail = data.user_has_lawsuit;
                                    if (user_has_trail === null) {
                                      return <React.Fragment></React.Fragment>;
                                    }


                                    var lawsuitLink = "#";
                                    if(user_has_trail.isDisable !== 1) {
                                      lawsuitLink = "/#/event/" + item.id;
                                    }

                                    return (

                                      <React.Fragment>
                                        <Col
                                          lg={4}
                                          md={4}
                                          sm={4}
                                          className="col"
                                          key={item.id}
                                        >
                                          <a href={lawsuitLink}>
                                            <div className="box-card">
                                              <div className="box-banner">
                                                <img
                                                  src={
                                                    item.thumbnail
                                                      ? "/account/assets/" +
                                                        item.thumbnail
                                                      : box_banner
                                                  }
                                                />
                                              </div>
                                              <div className="box-content">
                                                <div className="box-title-subtitle">
                                                  <div className="box-title">
                                                    {item.presenter}
                                                  </div>
                                                  <div className="box-title">
                                                    {item.title}
                                                  </div>
                                                  <div className="box-subtitle">
                                                    {item.subtitle}
                                                  </div>
                                                </div>
                                                <div className="box-subtitle-date">
                                                  {item.text_date}
                                                </div>
                                                {
                                                  user_has_trail.isDisable !== 1 && (
                                                  <Button className="btn-custom-zenite btn-custom-zenite-100">
                                                    Acessar Curso
                                                  </Button>
                                                  )
                                                }
                                                {
                                                  user_has_trail.isDisable === 1 && (
                                                  <strong className="color-red">Pendente documento para autorização da participação</strong>
                                                  )
                                                }
                                              </div>
                                            </div>
                                          </a>
                                        </Col>
                                        {(k + 1) % 3 === 0 && (
                                          <div className="both"></div>
                                        )}
                                      </React.Fragment>

                                  );
                                  }}
                                  </Query>
                              )
                            );
                          }}
                        </Query>
                      </Row>



                      <Query client={account_client} query={GET_LAWSUIT}>
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;

                            if (
                              !data.lawsuits_collections.lawsuits ||
                              !data.lawsuits_collections.lawsuits.length
                            ) {
                              return (
                                <div></div>
                              );
                            }

                            console.log("data.lawsuits_collections.lawsuits", data.lawsuits_collections.lawsuits);

                            return data.lawsuits_collections.lawsuits.map(
                              (item, k) => (
                                <div>
                                  {
                                    item.knowledgeTrail !== null && (
                                      <div>
                                        <Row>
                                          <Col lg={12}>
                                            <h2 className="h2-zenite">Trilha de capacitação</h2>
                                            <p>Uma oportunidade única para aprofundar seus conhecimentos e expandir suas competências em diversas áreas!</p>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col lg={6}>
                                            <h2 className="h2-zenite ft-36">{item.knowledgeTrail.title}</h2>
                                            <p>{item.knowledgeTrail.description}</p>
                                            <p><a href={item.knowledgeTrail.link} className="color-blue-important bold text-decoration"  target="_blank">Conheça agora as trilhas de capacitação</a></p>
                                          </Col>
                                          <Col lg={6}>
                                            <a href={item.knowledgeTrail.link} target="_blank">
                                              <img src={"/account/assets/" + item.knowledgeTrail.thumbnail} className="img-full-width" />
                                            </a>
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                  }
                                </div>
                            )
                            );
                          }}
                        </Query>






                    </div>
                  );
                }}
              </Query>
            </Grid>
          </div>
        </div>
        <div className="mt-7 background-bar">
          <div className="container footer-list-zenite">
            <div className="text-left container-fluid">
              <h3>Zênite</h3>
              <p>Há 35 anos ao seu lado</p>
              <p>resolvendo desafios na contratação pública.</p>
            </div>
            <div></div>
          </div>
        </div>
        <FooterZ />
      </div>
    );
  }
}

export default LawsuitList;
